import { useAuth0 } from '@auth0/auth0-react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonRouter } from '@ionic/react';
import React, { useEffect, useState } from 'react';

const Callback: React.FC = (props) => {
  const { location, history: { location: historyLocation } } = props as any;
  const [initialized, setInitialized] = useState(false);
  const { isLoading, handleRedirectCallback, getAccessTokenSilently } = useAuth0();
  const router = useIonRouter();
  useEffect(() => {
    const handler = async () => {
      setInitialized(true);
      try {
        await handleRedirectCallback(location.search.trim() !== '' ? location.search : historyLocation.search);
        await getAccessTokenSilently(); // so it is in cache
        router.push('/landing');
      } catch(e) {
        console.log(e);
      }
    };
    if (!isLoading && !initialized) {
      handler();
    }
  }, [
    location.search,
    historyLocation.search,
    handleRedirectCallback,
    getAccessTokenSilently,
    router,
    isLoading,
    initialized
  ]);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Logging in...</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen></IonContent>
    </IonPage>
  );
};

export default Callback;