import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter
} from '@ionic/react';
import { addCircleOutline } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { round } from 'lodash-es';
import * as omitDeep from 'omit-deep-lodash';
import { ListItem } from '../models';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import PubnubSubscription from '../components/ListDetail/PubnubSubscription';
import List from '../components/ListDetail/List';
import { currencyFormatter } from '../util/formatter';

export const GET_LIST_BY_ID = gql`
  query list($id: String!){
    list(id: $id) {
      id
      name
      sharedWith
      items {
        id
        name
        qty
        price
      }
    }
  }
`;

const UPDATE_LIST = gql`
  mutation update($list: UpdateList!, $action: UpdateAction){
    update(list: $list, action: $action) {
      id
      name
      sharedWith
      items {
        id
        name
        qty
        price
      }
    }
  }
`;

const ListDetail: React.FC = () => {
  const router = useIonRouter();
  const { id } = useParams<{ id: string; }>();
  const [total, setTotal] = useState(0);
  const [getListById, { data: listRes, loading: loadingItems }] = useLazyQuery(GET_LIST_BY_ID, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted({ list }) {
      if (list === null) {
        router.push('/landing');
      }
    }
  });
  const [updateList] = useMutation(UPDATE_LIST);
  const list = listRes && listRes.list ? listRes.list : {};
  const listName = list.name || 'Loading...';
  const listItems: ListItem[] = list.items || [];

  useEffect(() => {
    if (id) {
      getListById({
        variables: {id},
      });
    }
  }, [id, getListById]);

  useEffect(() => {
    setTotal(listItems.reduce((sum, item) =>
      round((((item.price || 0) * (item.qty || 0)) + sum), 2),
    0));
  }, [listItems]);

  const addItem = () => {
    const itemToAdd = {
      id: new Date().getTime().toString(),
      qty: 1,
      name: '',
      price: 0,
      __typename: 'ListItem',
    };
    const updatedList = {
      ...list,
      items: [
        itemToAdd,
        ...list.items,
      ],
    }
    let action;
    if (Array.isArray(list.sharedWith) && list.sharedWith.length > 0) {
      action = {
        type: 'ADD',
        item: omitDeep(itemToAdd, '__typename'),
      };
    }
    updateList({
      variables: {list: omitDeep(updatedList, '__typename'), action},
      optimisticResponse: {
        __typename: "Mutation",
        update: updatedList,
      },
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{listName}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={addItem}>
              <IonIcon slot="icon-only" icon={addCircleOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <TotalContainer className="total-bar">
          <Total>{`Total ${currencyFormatter.format(total)}`}</Total>
        </TotalContainer>
      </IonHeader>

      <IonContent fullscreen>
        <PubnubSubscription id={id} list={list} items={listItems} />
        <IonContent>
          <IonLoading
            isOpen={loadingItems}
            message={'Loading List'}
          />
          { listItems.length > 0 && <List list={list} /> }
        </IonContent>

      </IonContent>
    </IonPage>
  );
};

const Total = styled.p`
  padding: 0;
  margin-right: 15px;
  font-weight: bold;
  font-size: 15px;
`;

const TotalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    font-weight: bold;
    margin: 5px 0;
    padding-right: 23px;
  }
`;

export default ListDetail;
