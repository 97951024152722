import React from 'react';
import styled from 'styled-components';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon
} from '@ionic/react';
import { arrowUndoCircleOutline } from 'ionicons/icons';

const DefaultPage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Get Started</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Get Started</IonTitle>
          </IonToolbar>
        </IonHeader>
        <MessageContainer>
          <IonIcon size="large" slot="start" ios={arrowUndoCircleOutline} md={arrowUndoCircleOutline} />
          <strong>Create or Select a list</strong>
          <p>Explore lists in the menu</p>
        </MessageContainer>
      </IonContent>
    </IonPage>
  );
}

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  strong {
    font-size: 20px;
    line-height: 26px;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
  }
`;

export default DefaultPage;
