import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
const { App: CapApp } = Plugins;

export const AppUrlListener: React.FC<any> = () => {
  const history = useHistory();
  useEffect(() => {
    CapApp.addListener('appUrlOpen', (data: any) => {
      const slug = data.url.includes('estimate.danwakeem.com') ? data.url.split('estimate.danwakeem.com').pop() : data.url.split('estimateLists:/').pop();
      if (slug) {
        history.push(slug);
      }
    });
  }, [history]);

  return null;
};