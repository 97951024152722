import {
  IonContent,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonButton,
  IonLoading,
  IonAlert,
  IonSpinner,
} from '@ionic/react';

import React, { useEffect, useState } from 'react';
import { pricetagOutline, addCircleOutline, peopleOutline, personCircleOutline } from 'ionicons/icons';
import './Menu.css';
import ListItem from './ListItem';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { ContextRef } from '../../context/app.context';

const {
  REACT_APP_URL: url
} = process.env;

export const GET_LISTS = gql`
  query GET_LISTS {
    lists {
      id
      name
      sharedWith
    }
  }
`;

const CREATE_LIST = gql`
  mutation list($list: CreateList!){
    list(list: $list) {
      id
      name
      sharedWith
    }
  }
`;

interface AppPage {
  id: string;
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const Menu: React.FC = () => {
  const token = ContextRef.current?.token;
  const [createAlert, setCreateAlert] = useState(false);

  const [query, {data: listsRes, loading: loadingLists}] = useLazyQuery(GET_LISTS, {
    fetchPolicy: 'network-only',
  });
  const [createList] = useMutation(CREATE_LIST);
  const lists = listsRes ? listsRes.lists : [];
  const {
    isLoading,
    isAuthenticated,
    user,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const appPages: AppPage[] = (lists || []).map((list: any) => {
    const isShared = Array.isArray(list.sharedWith) && list.sharedWith.length > 0;
    return {
      ...list,
      url: `/list/${list.id}`,
      iosIcon: isShared ? peopleOutline : pricetagOutline,
      mdIcon: isShared ? peopleOutline : pricetagOutline
    } as AppPage
  });

  useEffect(() => {
    if (
      (!isLoading && isAuthenticated && token) || // logged in and token set
      (!isLoading && !isAuthenticated) // Not logged in and loading done
    ) {
      query();
    }
  }, [query, isLoading, isAuthenticated, token]);

  const showLoading = (loadingLists || isLoading);

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>
            <IonLabel>Your lists</IonLabel>
            <IonButton onClick={() => setCreateAlert(true)}>
              <IonIcon slot="icon-only" icon={addCircleOutline} />
            </IonButton>
          </IonListHeader>
          {showLoading && 
            <IonLoading
              isOpen={true}
              message={'Loading Lists'}
            />
          }
          {appPages.map((appPage, index) =>
            <ListItem key={index} listItem={appPage} />
          )}
        </IonList>
        <IonAlert
          isOpen={createAlert}
          onDidDismiss={() => setCreateAlert(false)}
          header={'Create List'}
          inputs={[
            {
              name: 'name',
              type: 'text',
              placeholder: 'List name'
            }
          ]}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Save',
              handler: (value: {name: string}) => {
                const list = {
                  name: value.name,
                };
                createList({
                  variables: {list},
                  optimisticResponse: {
                    __typename: "Mutation",
                    list: {
                      __typename: "List",
                      id: uuid(),
                      name: list.name,
                      dateCreated: Math.floor(new Date().getTime() / 1000),
                      sharedWith: [],
                    },
                  },
                  update: (proxy, { data: { list } }) => {
                    const data: any = proxy.readQuery({ query: GET_LISTS });
                    proxy.writeQuery({ query: GET_LISTS, data: {
                      lists: [
                        list,
                        ...data.lists,
                      ],
                    }});
                  },
                });
              }
            }
          ]}
        />
        {isLoading ? <StyledSpinner name="crescent" /> : <Login>
          {
            isAuthenticated ?
            <UserInfo>
              <p>{user.nickname || user.name}</p>
              <IonButton onClick={() => logout({ returnTo: `${url}/landing` })} size="default" color="light">
                Logout
              </IonButton>
            </UserInfo> :
            <IonButton onClick={() => loginWithRedirect()} expand="full">
              <IonIcon slot="start" icon={personCircleOutline} />
              Login
            </IonButton>
          }
        </Login>}
      </IonContent>
    </IonMenu>
  );
};

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSpinner = styled(IonSpinner)`
  position: absolute;
  bottom: 0;
  margin: 20px;
`;

const Login = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
`;

export default Menu;
