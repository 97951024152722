import React, { useEffect, useState } from 'react';
import { IonApp } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { Auth0Provider } from '@auth0/auth0-react';
import Layout from './Layout';
import { ApolloProvider } from '@apollo/client';
import { AppProvider } from './context/app.context';
import { getClient } from './context/apollo.client';
import { CapacitorStorageCache } from './util/customCache';
import { Plugins } from '@capacitor/core';
const { Device } = Plugins;

const {
  REACT_APP_AUTH0_DOMAIN: domain,
  REACT_APP_AUTH0_CLIENT_ID: clientId,
  REACT_APP_URL: url,
} = process.env;

const App: React.FC = () => {
  const [checkedDevice, setCheckedDevice] = useState(false);
  const [callback, setCallback] = useState('callback');
  const apolloClient = getClient;

  useEffect(() => {
    (async () => {
      const info = await Device.getInfo();
      if (info.platform === 'web') {
        setCallback('web/callback');
      }
      setCheckedDevice(true);
    })();
  }, [setCallback, setCheckedDevice]);

  return (
    <IonApp>
      {checkedDevice && <Auth0Provider
        customCache={CapacitorStorageCache}
        cacheLocation="custom"
        useRefreshTokens={true}
        domain={domain!}
        clientId={clientId!}
        redirectUri={`${url}/${callback}`}
        skipRedirectCallback={true}
      >
        <ApolloProvider client={apolloClient}>
          <AppProvider apolloClient={apolloClient}>
            <Layout />
          </AppProvider>
        </ApolloProvider>
      </Auth0Provider>}
    </IonApp>
  );
};

export default App;
