import { IonItem, IonInput, IonItemSliding, IonItemOptions, IonItemOption, IonLabel } from "@ionic/react";
import CurrencyInput from 'react-currency-masked-input'
import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { ListItem } from "../../models";
import { gql, useMutation } from "@apollo/client";
import * as omitDeep from 'omit-deep-lodash';

const UPDATE_LIST = gql`
  mutation update($list: UpdateList!, $action: UpdateAction){
    update(list: $list, action: $action) {
      id
      name
      sharedWith
      items {
        id
        name
        qty
        price
      }
    }
  }
`;

const Item: React.FC<{list: any, item: ListItem}> = ({
  list,
  item,
}) => {
  const { price } = item;
  const priceInput = useRef(null);
  const [updateList] = useMutation(UPDATE_LIST);
  const slideKey = `item-slider-${item.id}`;

  useEffect(() => {
    if (price && priceInput) {
      const currentValue = parseFloat((priceInput.current as any).value || 0);
      if (currentValue !== price) {
        (priceInput.current as any).state.value = price.toFixed(2);
      }
    }
  }, [price, priceInput]);

  const deleteMe = () => {
    (document.getElementById(slideKey) as any).close();
    const updatedItems = list.items.filter((listItem) => listItem.id !== item.id);
    const updatedList = {
      ...list,
      items: updatedItems,
    };

    let action;
    if (Array.isArray(list.sharedWith) && list.sharedWith.length > 0) {
      action = {
        type: 'REMOVE',
        item: omitDeep(item, '__typename'),
      };
    }

    updateList({
      variables: {list: omitDeep(updatedList, '__typename'), action},
      optimisticResponse: {
        __typename: "Mutation",
        update: updatedList,
      },
    });
  };

  const updateItem = (updatedItem) => {
    const updatedItems = list.items.map((listItem) => listItem.id === updatedItem.id ? updatedItem : listItem);
    if (JSON.stringify(updatedItems) !== JSON.stringify(list.items)) {
      const updatedList = {
        ...list,
        items: updatedItems,
      };

      let action;
      if (Array.isArray(list.sharedWith) && list.sharedWith.length > 0) {
        action = {
          type: 'UPDATE',
          item: omitDeep(updatedItem, '__typename'),
        };
      }
  
      updateList({
        variables: {list: omitDeep(updatedList, '__typename'), action},
        optimisticResponse: {
          __typename: "Mutation",
          update: updatedList,
        },
      });
    }
  }

  return (
    <IonItemSliding id={slideKey}>
      <IonItem>
        <QtyInput onIonBlur={(e) => updateItem({
          ...item,
          qty: parseInt((e.target as HTMLInputElement).value || '0'),
        })} value={item.qty} placeholder="Qty" type="number"></QtyInput>
        <ListInput onIonBlur={(e) => updateItem({
          ...item,
          name: (e.target as HTMLInputElement).value,
        })} value={item.name} placeholder="Name"></ListInput>
        <SmallLabel position="fixed">$</SmallLabel>
        <Currency ref={priceInput} autoComplete="off" defaultValue={(item.price || 0).toFixed(2)} type="tel" name="price-input" onBlur={(e) => updateItem({
            ...item,
            price: parseFloat((e.target as HTMLInputElement).value || '0'),
          })}></Currency>
      </IonItem>

      <IonItemOptions side="end">
        <IonItemOption color="danger" onClick={deleteMe}>Delete</IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );
};

const Currency = styled(CurrencyInput)`
  background: transparent;
  border: none;
  max-width: 60px;
  padding: 0;
  text-align: right;
  ::placeholder {
    color: inherit;
  }
  :focus {
    outline: none;
  }
`;

const SmallLabel = styled(IonLabel)`
  min-width: 10px !important;
  max-width: 10px !important;
`;

const ListInput = styled(IonInput)`
  padding: 0 10px !important;
`;

const QtyInput = styled(ListInput)`
  max-width: 40px;
  padding: 0 !important;
`;

export default Item;
