import { gql, useMutation } from "@apollo/client";
import { IonAlert, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonMenuToggle, IonModal } from "@ionic/react";
import { createOutline, removeCircleOutline, shareOutline, shareSocialOutline } from "ionicons/icons";
import React, { useContext, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Plugins } from '@capacitor/core';
import { GET_LISTS } from "./Menu";
import ShareModal from "../ShareModal";
import { useDeviceId } from "../../hooks/deviceId";
import { AppContext } from "../../context/app.context";
const { Share } = Plugins;

const RENAME_LIST = gql`
  mutation update($list: UpdateList!, $action: UpdateAction){
    update(list: $list, action: $action) {
      id
      name
      sharedWith
    }
  }
`;

const REMOVE_LIST = gql`
  mutation delete($listId: String!) {
    delete(listId: $listId)
  }
`;

const ListItem: React.FC<{
  listItem: any
}> = ({
  listItem
}) => {
  const { sub } = useContext(AppContext);
  const sharedWithMap = (listItem.sharedWith || []).reduce((obj, val) => ({
    ...obj,
    [val]: val,
  }), {});
  const userId = useDeviceId();
  const [showShareModal, setShowShareModal] = useState(false);
  const shareModal = useRef<any>(null);
  const [removeList] = useMutation(REMOVE_LIST);
  const [updateList] = useMutation(RENAME_LIST);
  const slideId = `slider-${listItem.id}`;
  const location = useLocation();
  const [renameAlert, setRenameAlert] = useState(false);

  const share = () => {
    (document.getElementById(slideId) as any).close();
    Share.share({
      title: 'Share this list with a friend',
      text: 'You have been invited to collaborate on a list :)',
      url: `https://estimate.danwakeem.com/share/${listItem.id}`,
      dialogTitle: 'Share with buddies'
    }).catch(() => {
      setShowShareModal(true);
    });
  }

  const rename = () => {
    (document.getElementById(slideId) as any).close();
    setRenameAlert(true);
  }

  const clickedRemove = () => {
    (document.getElementById(slideId) as any).close();
    const sharedId = sharedWithMap[userId || ''] || sharedWithMap[sub || ''];
    if (sharedId) {
      const list = {
        id: listItem.id,
        sharedWith: listItem.sharedWith.filter((id) => id !== sharedId),
      };
      const action = {
        type: 'REMOVE_USER',
        userId: sharedId,
      };
      updateList({
        variables: {list, action},
        update: (proxy) => {
          const data: any = proxy.readQuery({ query: GET_LISTS });
          const newSet = (data.lists || []).filter((list: any) => list.id !== listItem.id);
          proxy.writeQuery({
            query: GET_LISTS,
            data: {
              lists: newSet,
            },
          });
        }
      });
    } else {
      removeList({
        variables: {listId: listItem.id},
        update: (proxy, {data: { delete: removed }}) => {
          if (removed) {
            const data: any = proxy.readQuery({ query: GET_LISTS });
            const newSet = (data.lists || []).filter((list: any) => list.id !== listItem.id);
            proxy.writeQuery({
              query: GET_LISTS,
              data: {
                lists: newSet,
              },
            });
          }
        }
      });
    }
  }

  return (
    <>
      <IonMenuToggle autoHide={false}>
        <IonItemSliding id={slideId}>
          <IonItem className={location.pathname === listItem.url ? 'selected' : ''} routerLink={listItem.url} routerDirection="none" lines="none" detail={false}>
            <IonIcon slot="start" ios={listItem.iosIcon} md={listItem.mdIcon} />
            <IonLabel>{listItem.name}</IonLabel>
          </IonItem>
          <IonItemOptions>
            <IonItemOption onClick={share} color="primary">
              <IonIcon slot="bottom" ios={shareOutline} md={shareSocialOutline}></IonIcon>
              Share
            </IonItemOption>
            <IonItemOption onClick={rename} color="secondary">
              <IonIcon slot="bottom" icon={createOutline} />
              Rename
            </IonItemOption>
            <IonItemOption color="danger" onClick={clickedRemove}>
              <IonIcon slot="bottom" icon={removeCircleOutline} />
              Delete
            </IonItemOption>
          </IonItemOptions>
        </IonItemSliding>
      </IonMenuToggle>
      <IonAlert
        isOpen={renameAlert}
        onDidDismiss={() => setRenameAlert(false)}
        header={'Rename List'}
        inputs={[
          {
            name: 'name',
            value: listItem.name,
            type: 'text',
            placeholder: 'List name'
          }
        ]}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Save',
            handler: (value: {name: string}) => {
              const list = {
                id: listItem.id,
                name: value.name,
              };
              let action;
              if (Array.isArray(listItem.sharedWith) && listItem.sharedWith.length > 0) {
                action = {
                  title: value.name,
                  type: 'RENAMED'
                };
              }

              updateList({
                variables: {list, action},
                optimisticResponse: {
                  __typename: "Mutation",
                  list: {
                    __typename: "List",
                    id: listItem.id,
                    sharedWith: listItem.sharedWith,
                    name: list.name,
                  },
                }
              });
            }
          }
        ]}
      />
      <IonModal
        ref={shareModal}
        isOpen={showShareModal}
        keyboardClose={true}
        cssClass='my-custom-class'
        swipeToClose={true}
        onDidDismiss={() => setShowShareModal(false)}>
        <ShareModal id={listItem.id} dismiss={() => shareModal.current.dismiss()} />
      </IonModal>
    </>
)
};

export default ListItem;
