import { ApolloClient, ApolloLink, createHttpLink, HttpOptions, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { get } from 'lodash';
import gql from 'graphql-tag';
import * as jwt from 'jsonwebtoken';
import { Plugins } from '@capacitor/core';
import { ContextRef } from './app.context';
const { Device } = Plugins;

const {
  REACT_APP_GRAPHQL_ENDPOINT: GRAPHQL_API_ENDPOINT,
  REACT_APP_CYPRESS: CYPRESS,
  REACT_APP_JWT_SIGNATURE: JWT_KEY
} = process.env;

const getCorrelationIds = (context: any) => {
  try {
    const headers = get(context, `response.headers`);
    return {
      'x-correlation-id': headers.get('x-correlation-id'),
      'x-correlation-user-id': headers.get('x-correlation-user-id'),
      'x-correlation-org-id': headers.get('x-correlation-org-id'),
      'x-correlation-package-id': headers.get('x-correlation-package-id'),
    };
  } catch (e) {
    return '';
  }
};

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const correlationIds = getCorrelationIds(operation.getContext());
    const correlationId = get(correlationIds, 'x-correlation-id');
    graphQLErrors.forEach(({ message, path, locations }) => {
      const errorMessage = `[GraphQL error]: Message: ${message}, Path: ${path}, CorrelationId: ${correlationId}`;
      console.log(errorMessage);
    });
  }

  if (networkError) {
    const errorMessage = `[Network error]: ${networkError}`;
    console.log(errorMessage);
  }
});

const customFetch = (uri: string, options: any) => {
  const { query } = JSON.parse(options.body);
  const obj = gql`
    ${query}
  `;
  return fetch(
    `${GRAPHQL_API_ENDPOINT}?operationName=${encodeURI(
      get(obj, 'definitions[0].selectionSet.selections[0].name.value')
    )}`,
    options
  );
};

const httpLink = () => {
  let options: HttpOptions = {
    uri: GRAPHQL_API_ENDPOINT,
  };
  if (CYPRESS) {
    options = { fetch: customFetch };
  }
  return createHttpLink(options);
};

const authLink = setContext(async (_, { headers }) => {
  let token = ContextRef.current?.token;
  if (!token) {
    const info = await Device.getInfo();
    const userId = btoa(info.uuid.split('-').join(btoa(info.uuid)));
    token = jwt.sign({
      userId,
      iat: Math.floor(Date.now() / 1000) - 10,
    }, JWT_KEY || '');
  }
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const getClient = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink()]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export { getClient };
