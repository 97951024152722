import React, { useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { IonContent, IonHeader, IonLoading, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import * as omitDeep from 'omit-deep-lodash';
import { GET_LISTS } from "../components/Menu/Menu";
import { GET_LIST_BY_ID } from "./ListDetail";
import { useHistory, useParams } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";

const SHARING_LIST = gql`
  mutation addSharedList($listId: String!){
    addSharedList(listId: $listId) {
      id
      name
      sharedWith
      items {
        id
        name
        qty
        price
      }
    }
  }
`;

const SharePage: React.FC = () => {
  const { isLoading } = useAuth0();
  const history = useHistory();
  const { listId } = useParams<{ listId: string; }>();
  const [addSharedList, {loading}] = useMutation(SHARING_LIST, {
    onCompleted({ addSharedList: list }) {
      history.push(`/list/${list.id}`);
    },
    update: (proxy, { data: { addSharedList: list } }) => {
      const data: any = proxy.readQuery({ query: GET_LISTS });
      if (data && data.lists) {
        const alreadyAdded = data.lists.find((l) => l.id === list.id);
        if (!alreadyAdded) {
          proxy.writeQuery({ query: GET_LISTS, data: {
            lists: [
              omitDeep(list, 'items'),
              ...data.lists,
            ],
          }});
        }
      }
      proxy.writeQuery({
        query: GET_LIST_BY_ID,
        variables: { id: list.id },
        data: list,
      });
    },
  });

  useEffect(() => {
    if(listId && !isLoading) {
      setTimeout(() => {
        addSharedList({
          variables: {listId},
        });
      }, 500);
    }
  }, [listId, addSharedList, isLoading]);

  const showLoading = isLoading || loading;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Sharing list...</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {showLoading && <IonLoading
          isOpen={true}
          message={'Sharing list...'}
        />}
      </IonContent>
    </IonPage>
  );
};

export default SharePage;
