import { usePubNub } from 'pubnub-react';
import React, { useEffect, useContext } from 'react';
import { emitCustomEvent, useCustomEventListener } from 'react-custom-events';
import { AppContext } from '../../context/app.context';
import { ListItem } from '../../models';

interface SubscriptionInterface {
  id: string
  list: any
  items: ListItem[]
}

const PubnubSubscription: React.FC<SubscriptionInterface> = ({
  id,
  list,
  items,
  children
}) => {
  const pubnub = usePubNub();
  const { userId, sub, apolloClient: { cache } } = useContext(AppContext);
  const userIds = [userId, sub];
  useCustomEventListener('new-message', ({ message, userMetadata }) => {
    const messageList = list;
    if (!userIds.includes(userMetadata.userId)) {
      const { type, title, userId: sharedUserId } = message;
      let { item } = message;
      item = {
        ...item,
        __typename: 'Item',
      };
      switch(type) {
        case 'UPDATE': {
          cache.modify({
            id: cache.identify(messageList),
            fields: {
              items: () => items.map((it) => it.id === item.id ? item : it)
            }
          });
          break;
        }
        case 'ADD': {
          cache.modify({
            id: cache.identify(messageList),
            fields: {
              items: () => [item, ...items]
            }
          });
          break;
        }
        case 'REMOVE': {
          cache.modify({
            id: cache.identify(messageList),
            fields: {
              items: () => items.filter((it) => it.id !== item.id),
            }
          });
          break;
        }
        case 'RENAMED': {
          cache.modify({
            id: cache.identify(messageList),
            fields: {
              name: () => title
            }
          });
          break;
        }
        case 'ADD_USER': {
          cache.modify({
            id: cache.identify(messageList),
            fields: {
              sharedWith: (sharedList) => {
                return Array.from(new Set([...sharedList, sharedUserId]))
              },
            }
          });
          break;
        }
        case 'REMOVE_USER': {
          cache.modify({
            id: cache.identify(messageList),
            fields: {
              sharedWith: (sharedList) => sharedList.filter((u) => u !== sharedUserId),
            }
          });
          break;
        }
        default:
          console.log('Nothing to do :)');
      }
    }
  });

  useEffect(() => {
    const listener = {
      message: (res) => {
        emitCustomEvent('new-message', res);
      }
    };
    pubnub.addListener(listener);
    pubnub.subscribe({ channels: [id] });
    return function cleanup() {
      pubnub.removeListener(listener);
    }
  }, [id, pubnub]);

  return (<>{children}</>);
}

export default PubnubSubscription;