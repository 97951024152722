import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { closeOutline, shareOutline, clipboardOutline } from "ionicons/icons";
import React from "react";
import styled from "styled-components";
import { Plugins } from '@capacitor/core';
const { Clipboard } = Plugins;


interface Input {
  id: string
  dismiss: any
}

const ShareModal: React.FC<Input> = ({
  id,
  dismiss
}) => {
  const link = `https://estimate.danwakeem.com/share/${id}`;
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={dismiss}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle><span role="img" aria-label="friends">👯‍♀️</span> Sharing is caring <span role="img" aria-label="friends">👯‍♀️</span></IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <MessageContainer>
          <IonIcon style={{fontSize: '100px'}} color="primary" slot="start" ios={shareOutline} md={shareOutline} />
          <strong>Start sharing</strong>
          <p>Copy this link and send it to a friend</p>
          <ShareButtonContainer>
            <ShareText>{`https://estimate.danwakeem.com/share/${id}`}</ShareText>
            <IonButton onClick={() => Clipboard.write({
              string: link
            })} color="medium"><IonIcon slot="icon-only" icon={clipboardOutline} /></IonButton>
          </ShareButtonContainer>
        </MessageContainer>
      </IonContent>
    </IonPage>
  )
};

const ShareButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ShareText = styled.p`
  padding: 7px;
  max-width: 250px;
  overflow-x: scroll;
  display: inherit;
  background-color: var(--ion-color-light-shade);
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  padding: 30px;
  transform: translateY(-50%);

  strong {
    font-size: 20px;
    line-height: 26px;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
  }
`;

export default ShareModal;