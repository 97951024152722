import { useState, useEffect } from 'react';
import { Plugins } from '@capacitor/core';
const { Storage, Device } = Plugins;

export const useDeviceId = () => {
  const key = 'estimate:deviceId';
  const [deviceId, setDeviceId] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      let id = await Storage.get({ key }).then((res) => res.value).catch(() => null);
      if (!id) {
        const info = await Device.getInfo();
        const uId = btoa(info.uuid.split('-').join(btoa(info.uuid)));
        id = uId;
        await Storage.set({ key, value: id });
      }
      setDeviceId(id);
    })();
  }, []);

  return deviceId;
};