import { IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { PubNubProvider } from "pubnub-react";
import React, { useContext, useEffect } from "react";
import { Redirect, Route } from "react-router";
import { AppUrlListener } from "./components/AppUrlListener";
import Menu from "./components/Menu/Menu";
import DefaultPage from "./pages/DefaultPage";
import ListDetail from "./pages/ListDetail";
import SharePage from "./pages/SharePage";
import PubNub from 'pubnub';
import { useAuth0 } from "@auth0/auth0-react";
import { AppContext } from "./context/app.context";
import Callback from "./pages/Callback";

const pubnub = new PubNub({
  subscribeKey: process.env.REACT_APP_PUBNUB_SUB_KEY
});

const Layout: React.FC = () => {
  const { token, setToken, setSub } = useContext(AppContext);
  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    const getToken = async () => {
      if (isAuthenticated && !token) {
        const {__raw: authToken, sub} = await getIdTokenClaims();
        setToken(authToken);
        setSub(sub);
      }
    };

    getToken();
  }, [getIdTokenClaims, setSub, setToken, isAuthenticated, token]);

  return (
    <PubNubProvider client={pubnub}>
      <IonReactRouter>
        <AppUrlListener></AppUrlListener>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/landing" component={DefaultPage} exact />
            <Route path="/web/landing" component={DefaultPage} exact />
            <Route path="/list/:id" component={ListDetail} exact />
            <Route path="/share/tab/lists/:listId" component={SharePage} exact />
            <Route path="/share/:listId" component={SharePage} exact />
            <Route path="/web/share/:listId" component={SharePage} exact />
            <Route path="/callback" component={Callback} exact />
            <Route path="/web/callback" component={Callback} exact />
            <Redirect from="/" to='/landing' exact />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </PubNubProvider>
  );
};

export default Layout;
