import React, { useCallback, useEffect, useState } from "react";
import { Plugins } from '@capacitor/core';
import { ApolloClient } from "@apollo/client";
const { Device } = Plugins;

export const AppContext = React.createContext<{
  deviceId?: string,
  token?: string,
  sub?: string,
  userId?: string,
  apolloClient: ApolloClient<any>
  setToken: any,
  setSub: any,
}>({} as any);

export const ContextRef = React.createRef<{
  deviceId?: string,
  token?: string,
  userId?: string,
  apolloClient: ApolloClient<any>
  setToken: any
}>();

export const AppProvider = (props: any) => {
  const [token, setToken] = useState<string>();
  const [sub, setSub] = useState<string>();
  const [deviceId, setDeviceId] = useState<string>();
  const [userId, setUserId] = useState<string>();
  const [apolloClient] = useState<ApolloClient<any>>(props.apolloClient);

  const getDeviceInfo = useCallback(async () => {
    if (!deviceId) {
      const info = await Device.getInfo();
      setDeviceId(info.uuid);
      const uId = btoa(info.uuid.split('-').join(btoa(info.uuid)));
      setUserId(uId);
    }
  }, [deviceId, setDeviceId]);

  useEffect(() => {
    getDeviceInfo();
  }, [getDeviceInfo]);

  const contextValues = {
    deviceId,
    apolloClient,
    userId,
    token,
    setToken,
    setSub,
    sub,
  };

  React.useImperativeHandle(ContextRef, () => contextValues);

  return (
    <AppContext.Provider
      value={contextValues}
    >
      {props.children}
    </AppContext.Provider>
  );
}
