import { IonItem, IonList, IonSearchbar } from "@ionic/react";
import React, { useState } from "react";
import styled from "styled-components";
import Item from './Item';

interface Input {
  list: any
}

const List: React.FC<Input> = ({
  list
}) => {
  const items = list.items || [];
  const [searchText, setSearchText] = useState('');

  return (
    <IonList>
      <IonItem>
        <SearchBar showCancelButton="never" onIonChange={e => setSearchText(e.detail.value || '')} />
      </IonItem>
      {
        items
        .filter((item) => {
          if (searchText === '') return true;
          return item.name.toUpperCase().includes(searchText.toUpperCase());
        })
        .map((item, index) => <Item list={list} item={item} key={index}></Item>)
      }
    </IonList>
  )
};

const SearchBar = styled(IonSearchbar)`
  --box-shadow: none;
  padding: 0;
`;

export default List;
